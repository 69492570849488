import { useStore } from '@nanostores/react'
import { $step, prevStep } from '../store/step'

export default function Header() {
  const step = useStore($step)

  return (
    <header className="relative bg-neutral-50 pt-[149px] pb-[261px] lg:py-16 border-b border-secondary-jungle">
      <div className="container px-6 max-w-[800px] mx-auto">
        <h1 className="text-neutral-600 text-4xl md:text-6xl text-center font-normal leading-[43.2px] md:leading-[66px] max-w-[328px] md:max-w-none mx-auto mb-6">
          {step === 1 && 'Which plan would you like to get started with?'}
          {step === 2 && 'Are you ready to build something incredible?'}
          {step === 3 && 'Almost done! Select your payment method'}
        </h1>
        <h2 className="text-neutral-600 text-2xl text-center leading-[31.68px] mb-12">
          {step === 1 && 'Start with a 7 day money back guarentee, risk free'}
          {step === 2 && 'Sign up below!'}
          {step === 3 && 'Secure & simple payment options with Stripe'}
        </h2>

        <div className="lg:hidden flex items-center justify-center mb-8">
         <svg width="1" height="48" viewBox="0 0 1 48" fill="none">
            <path d="M0.5 0V48" stroke="#666666"/>
          </svg>
        </div>

        <div className="lg:hidden">
          <h6 className="text-neutral-400 text-center text-sm leading-[16.8px] uppercase">
            Scroll to join
          </h6>
        </div>
      </div>

      <div className="absolute left-0 bottom-0 hidden sm:block">
        <svg width="332" height="247" viewBox="0 0 332 247" fill="none">
          <path d="M-30.207 194.034C-43.2754 187.593 -49.8065 177.431 -47.4802 162.751C-45.8319 152.34 -36.6261 143.121 -26.3879 141.542C-11.9324 139.308 -1.53868 145.262 5.03593 158.969C11.9153 145.237 22.4273 138.696 37.1502 141.798C48.2717 144.139 55.1635 151.797 57.5209 162.932C60.1334 175.278 54.9769 184.534 40.5463 194.315C53.8697 201.187 60.3572 211.76 57.1788 226.728C54.8649 237.632 45.7152 245.902 34.6186 247.094C20.8287 248.579 11.2 242.119 5.07944 229.206C-1.58848 242.619 -11.7334 249.466 -26.4812 246.657C-37.7893 244.504 -45.8318 235.678 -47.6667 224.793C-49.7629 212.359 -44.1213 202.304 -30.207 194.034Z" fill="#1F3934"/>
          <path d="M58.5 194.205C85.6622 185.717 103.096 168.791 111.651 141C120.301 168.149 137.45 185.418 164.764 194.205C137.19 202.973 120.136 220.204 111.803 247.264C103.064 220.109 86.0236 202.674 58.5 194.205Z" fill="#FFE2DA"/>
          <path d="M226.558 194.171C226.558 177.678 226.616 161.186 226.501 144.693C226.479 142.015 226.954 140.945 229.96 141.046C240.719 141.419 251.507 140.687 262.267 141.483C290.833 143.587 316.106 168.078 318.724 196.569C320.199 212.652 319.005 228.814 319.335 244.941C319.379 246.894 318.566 247.289 316.768 247.282C287.554 247.231 258.347 247.21 229.133 247.303C226.673 247.31 226.522 246.305 226.522 244.359C226.58 227.63 226.551 210.893 226.558 194.164V194.171Z" fill="#FF613A"/>
          <path d="M59.2529 79.1342C83.2926 63.9049 95.7515 43.0434 96.8224 13.9854C112.205 37.9705 133.238 50.2128 161.896 51.631C137.531 67.237 125.518 88.2945 124.473 116.589C109.003 92.6211 88.0308 80.1913 59.2529 79.1342Z" fill="#B9E2F9"/>
          <path d="M201.225 146.279C203.85 151.041 204.494 155.873 202.667 160.925C200.734 166.266 196.821 169.477 191.477 170.844C185.741 172.311 180.726 170.687 176.438 166.645C175.066 165.354 174.993 165.313 173.651 166.68C170.732 169.65 167.086 171.225 163.086 171.369C156.763 171.594 150.419 168.223 147.681 161.656C145.508 156.445 146.085 151.285 148.883 146.263C147.476 145.337 146.002 144.509 144.611 143.548C138.213 139.119 135.679 127.595 142.759 120.224C145.878 116.978 149.575 115.421 153.815 114.723C154.809 114.56 155.75 114.853 156.72 115.072C158.405 115.451 159.13 115.018 159.356 113.299C159.849 109.531 161.65 106.496 164.396 104.035C168.975 99.9285 175.68 98.8835 181.211 101.236C185.992 103.269 189.003 106.829 190.525 111.745C190.738 112.433 191.054 113.109 191.132 113.81C191.291 115.234 192.185 115.329 193.253 115.118C197.872 114.203 201.899 115.573 205.462 118.44C212.097 123.778 213.577 132.414 209.084 139.661C207.294 142.546 206.257 143.367 201.222 146.279H201.225Z" fill="#C7F494"/>
        </svg>
      </div>

      <div className="absolute right-0 bottom-0 hidden sm:block">
        <svg width="272" height="212" viewBox="0 0 272 212" fill="none">
          <path d="M0.573254 176.7C0.573254 166.894 0.486849 157.096 0.587671 147.291C0.818122 124.846 19.031 106.463 41.5431 106.162C57.8547 105.946 74.1663 106.162 90.478 106.04C92.9193 106.018 93.4882 106.75 93.481 109.069C93.3946 129.031 93.5242 148.993 93.3874 168.954C93.2218 194.135 75.2538 212.015 50.0122 212.195C34.6584 212.302 19.3046 212.13 3.95801 212.302C0.91894 212.338 0.472449 211.24 0.501256 208.613C0.630884 197.975 0.55165 187.337 0.55165 176.7H0.573254Z" fill="#1F3934"/>
          <path d="M140.421 128.637C139.347 124.574 139.566 121.257 142.568 118.656C145.869 115.797 149.478 116.077 153.307 118.014C153.467 113.899 154.92 110.64 158.903 109.279C162.667 107.994 165.974 109.087 168.578 111.965C169.506 111.971 169.556 111.225 169.821 110.732C171.456 107.689 173.986 105.965 177.481 106.041C180.952 106.115 183.497 107.814 184.975 110.972C185.185 111.423 185.431 111.856 185.731 112.434C188.532 109.096 191.944 107.884 195.942 109.367C199.764 110.784 201.282 113.872 201.205 118.044C204.876 116.22 208.433 115.705 211.703 118.318C214.967 120.928 215.569 124.358 214.181 128.445C218.136 128.32 221.736 128.856 223.855 132.483C225.965 136.099 225.293 139.482 222.544 142.826C226.595 143.856 229.692 145.851 230.368 150.014C231.031 154.104 229.06 157.04 225.268 159.123C229.023 161.194 231.05 164.191 230.365 168.297C229.692 172.35 226.789 174.507 222.569 175.381C225.055 178.725 226.033 182.087 223.957 185.681C221.943 189.171 218.571 190.082 214.489 189.954C215.304 193.834 215.072 197.401 211.802 199.947C208.618 202.426 205.135 202.054 201.384 200.327C201.131 204.348 199.789 207.613 195.825 209.004C191.87 210.393 188.587 209.218 185.795 205.874C184.092 209.598 181.572 212.339 177.318 212.303C173.224 212.269 170.524 209.933 169.133 206.117C161.643 213.073 153.514 208.505 153.187 200.58C143.87 204.29 138.045 197.906 140.263 189.933C131.946 190.658 126.39 183.641 131.814 175.433C127.519 174.358 124.576 171.942 124.255 167.371C123.99 163.616 126.06 161.073 129.361 159.175C125.687 157.037 123.681 154.119 124.425 149.84C125.159 145.619 128.164 143.804 132.261 142.933C129.571 139.741 128.605 136.373 130.666 132.751C132.705 129.164 136.065 128 140.424 128.628L140.421 128.637Z" fill="#5955FF"/>
          <path d="M249.48 191.226C257.25 183.381 265.039 175.517 273.959 166.502C269.493 166.502 266.493 166.502 263.499 166.502C256.467 166.502 249.442 166.452 242.411 166.545C239.877 166.576 238.877 165.848 238.759 163.036C238.287 151.912 238.162 151.9 249.032 151.837C255.443 151.8 261.853 151.875 268.257 151.85C269.654 151.85 271.145 152.273 272.897 150.842C265.083 142.965 257.306 135.132 249.461 127.225C251.666 123.871 254.759 121.706 257.169 118.913C258.94 116.859 260.3 116.978 262.17 118.913C267.617 124.58 273.269 130.055 278.767 135.68C280.201 137.148 281.357 138.878 282.456 140.253C285.171 138.884 284.332 137.223 284.344 135.96C284.431 127.262 284.469 118.558 284.344 109.86C284.307 107.21 285.14 106.357 287.848 106.227C299.246 105.679 299.233 105.586 299.227 116.934C299.227 124.512 299.227 132.09 299.227 141.167C308.047 132.42 315.855 124.674 323.812 116.785C327.812 119.939 331.024 123.367 334.75 127.741C326.676 135.481 318.663 143.158 309.594 151.85C318.955 151.85 326.924 151.806 334.887 151.862C347.055 151.943 344.558 150.45 344.807 162.147C344.894 166.371 344.72 166.464 340.241 166.495C330.601 166.558 320.961 166.514 309.737 166.514C318.495 175.38 326.234 183.225 333.875 190.959C333.726 191.587 333.757 192.079 333.533 192.303C330.477 195.339 327.955 200.565 324.135 200.708C320.253 200.851 317.731 195.507 314.644 192.552C309.762 187.873 305.14 182.933 299.208 178.509C299.208 181.913 299.208 185.316 299.208 188.719C299.208 195.557 299.165 202.388 299.233 209.225C299.252 211.254 298.637 212.324 296.426 212.255C293.531 212.162 290.63 212.1 287.748 212.286C284.885 212.473 284.313 211.086 284.344 208.585C284.45 201.336 284.382 194.088 284.382 186.834C284.382 184.171 284.382 181.508 284.382 178.783C282.046 178.497 281.332 180.32 280.264 181.365C274.35 187.157 268.567 193.08 262.679 198.898C259.642 201.896 259.511 201.915 256.43 198.779C254.132 196.44 251.486 194.412 249.48 191.226Z" fill="#C7F494"/>
          <path d="M211.688 23.092C210.615 19.0291 210.834 15.7124 213.836 13.1115C217.137 10.2517 220.746 10.5319 224.575 12.4689C224.735 8.35425 226.188 5.09543 230.171 3.73405C233.934 2.4488 237.242 3.54218 239.845 6.42028C240.774 6.42637 240.823 5.6802 241.089 5.18681C242.724 2.14424 245.253 0.420421 248.749 0.496561C252.219 0.569655 254.765 2.26911 256.242 5.42741C256.452 5.87816 256.699 6.31064 256.998 6.8893C259.799 3.55131 263.211 2.33916 267.21 3.82237C271.032 5.23858 272.55 8.32683 272.473 12.4993C276.144 10.675 279.701 10.1603 282.971 12.7734C286.235 15.3835 286.837 18.8129 285.448 22.9001C289.403 22.7752 293.004 23.3112 295.123 26.9386C297.233 30.5537 296.561 33.9374 293.812 37.2815C297.862 38.3109 300.96 40.3058 301.635 44.4691C302.299 48.5594 300.327 51.4953 296.536 53.5785C300.29 55.6495 302.317 58.6464 301.632 62.7519C300.96 66.8056 298.057 68.9619 293.836 69.836C296.323 73.1801 297.301 76.5424 295.225 80.1362C293.21 83.6265 289.838 84.5371 285.757 84.4092C286.571 88.2893 286.34 91.8558 283.07 94.4019C279.886 96.881 276.403 96.5094 272.652 94.7826C272.399 98.8028 271.057 102.068 267.092 103.46C263.137 104.848 259.855 103.673 257.063 100.329C255.36 104.053 252.84 106.794 248.585 106.758C244.491 106.724 241.792 104.388 240.401 100.572C232.91 107.528 224.781 102.96 224.454 95.0354C215.137 98.7449 209.313 92.3613 211.531 84.3879C203.214 85.1128 197.658 78.0957 203.081 69.8878C198.787 68.8127 195.844 66.3975 195.523 61.826C195.258 58.0708 197.328 55.5277 200.629 53.6303C196.954 51.4923 194.949 48.5746 195.692 44.2955C196.427 40.0743 199.432 38.2591 203.528 37.3881C200.838 34.1963 199.873 30.8278 201.934 27.2066C203.973 23.6189 207.332 22.4554 211.691 23.0828L211.688 23.092Z" fill="#FF613A"/>
          <path d="M92.6883 22.5978C91.6148 18.535 91.8338 15.2183 94.8355 12.6173C98.1365 9.75752 101.746 10.0377 105.575 11.9747C105.735 7.86011 107.188 4.60129 111.171 3.23991C114.934 1.95466 118.242 3.04803 120.845 5.92614C121.774 5.93223 121.823 5.18606 122.089 4.69267C123.724 1.6501 126.253 -0.0737201 129.749 0.00242018C133.219 0.0755148 135.765 1.77497 137.242 4.93327C137.452 5.38402 137.699 5.8165 137.998 6.39516C140.799 3.05717 144.211 1.84502 148.21 3.32823C152.032 4.74444 153.55 7.83269 153.473 12.0052C157.144 10.1809 160.701 9.66615 163.971 12.2793C167.235 14.8894 167.837 18.3187 166.448 22.4059C170.403 22.2811 174.004 22.8171 176.123 26.4444C178.233 30.0596 177.561 33.4432 174.812 36.7873C178.862 37.8167 181.96 39.8116 182.635 43.975C183.299 48.0652 181.327 51.0012 177.536 53.0844C181.29 55.1554 183.317 58.1523 182.632 62.2578C181.96 66.3115 179.057 68.4678 174.836 69.3419C177.323 72.6859 178.301 76.0483 176.225 79.6421C174.21 83.1324 170.838 84.043 166.757 83.9151C167.571 87.7952 167.34 91.3616 164.07 93.9077C160.886 96.3869 157.403 96.0153 153.652 94.2884C153.399 98.3086 152.057 101.574 148.092 102.965C144.137 104.354 140.855 103.179 138.063 99.8345C136.36 103.559 133.84 106.3 129.585 106.264C125.491 106.23 122.792 103.894 121.401 100.078C113.91 107.034 105.781 102.466 105.454 94.5412C96.1374 98.2508 90.3129 91.8672 92.531 83.8938C84.2138 84.6186 78.6577 77.6015 84.0811 69.3936C79.7868 68.3185 76.8437 65.9034 76.5228 61.3319C76.2575 57.5767 78.3276 55.0336 81.6285 53.1362C77.9543 50.9981 75.949 48.0804 76.6925 43.8014C77.4267 39.5801 80.4315 37.765 84.5285 36.8939C81.8383 33.7021 80.8727 30.3337 82.9335 26.7124C84.9727 23.1247 88.3323 21.9613 92.6914 22.5887L92.6883 22.5978Z" fill="#B9E2F9"/>
        </svg>
      </div>

      <div className="absolute bottom-0 left-0 right-0 sm:hidden">
        <svg viewBox="0 0 375 265" fill="none" className="w-full">
          <g clipPath="url(#clip0_268_3055)">
            <path d="M66.057 213C66.057 196.858 66.1133 180.717 66.0007 164.576C65.9796 161.955 66.4441 160.908 69.3863 161.006C79.9164 161.372 90.4746 160.655 101.005 161.435C128.963 163.494 153.697 187.463 156.259 215.347C157.702 231.087 156.534 246.905 156.858 262.688C156.9 264.599 156.104 264.986 154.345 264.979C125.753 264.93 97.1685 264.909 68.5769 265C66.1696 265.007 66.0218 264.023 66.0218 262.119C66.0781 245.746 66.0499 229.366 66.057 212.993V213Z" fill="#FF613A"/>
            <path d="M172.055 238.196C172.055 230.814 171.99 223.438 172.066 216.056C172.239 199.159 185.951 185.319 202.899 185.092C215.179 184.93 227.459 185.092 239.739 185C241.577 184.984 242.005 185.535 242 187.281C241.935 202.309 242.032 217.337 241.929 232.365C241.805 251.322 228.278 264.783 209.275 264.918C197.716 264.999 186.157 264.87 174.603 264.999C172.315 265.026 171.979 264.2 172.001 262.222C172.099 254.213 172.039 246.205 172.039 238.196H172.055Z" fill="#1F3934"/>
            <path d="M57.7153 149.821C59.436 155.667 58.9718 161.111 55.7501 166.197C52.3432 171.574 47.2839 174.141 41.1045 174.344C34.4732 174.562 29.3772 171.571 25.6625 166.111C24.4737 164.366 24.4039 164.304 22.6043 165.475C18.6917 168.019 14.3199 168.861 9.90828 168.05C2.9346 166.767 -3.19295 161.544 -4.60072 153.694C-5.71723 147.466 -3.83777 141.959 0.438598 137.14C-0.877014 135.786 -2.28994 134.523 -3.57934 133.135C-9.5098 126.741 -9.49426 113.516 0.037198 107.162C4.23492 104.364 8.6581 103.556 13.4665 103.817C14.5941 103.88 15.5527 104.427 16.5615 104.902C18.3132 105.724 19.2114 105.426 19.875 103.599C21.3264 99.5947 24.0307 96.7093 27.6315 94.6808C33.6361 91.2944 41.227 91.7731 46.7101 95.6855C51.4505 99.067 53.8838 103.691 54.3609 109.439C54.4275 110.243 54.6089 111.06 54.5248 111.846C54.3543 113.443 55.3106 113.763 56.5297 113.791C61.8063 113.907 65.8814 116.38 69.0869 120.379C75.0569 127.827 74.5869 137.635 67.917 144.479C65.2605 147.204 63.9265 147.85 57.7123 149.82L57.7153 149.821Z" fill="#C7F494"/>
            <path d="M149.75 137.61C149.103 135.163 149.235 133.166 151.043 131.599C153.031 129.877 155.205 130.045 157.511 131.212C157.607 128.734 158.483 126.771 160.881 125.951C163.148 125.177 165.14 125.836 166.708 127.569C167.267 127.573 167.297 127.123 167.457 126.826C168.442 124.994 169.965 123.956 172.07 124.001C174.161 124.045 175.693 125.069 176.583 126.971C176.71 127.243 176.858 127.503 177.039 127.852C178.726 125.841 180.781 125.111 183.189 126.005C185.491 126.857 186.405 128.717 186.359 131.23C188.57 130.132 190.712 129.822 192.681 131.395C194.647 132.967 195.01 135.033 194.173 137.494C196.555 137.419 198.724 137.742 200 139.927C201.271 142.104 200.866 144.142 199.211 146.156C201.65 146.776 203.516 147.977 203.922 150.485C204.322 152.948 203.135 154.717 200.851 155.971C203.112 157.219 204.333 159.024 203.921 161.496C203.516 163.938 201.767 165.236 199.225 165.763C200.723 167.777 201.312 169.802 200.062 171.966C198.848 174.068 196.817 174.617 194.359 174.54C194.85 176.877 194.71 179.025 192.741 180.558C190.823 182.051 188.726 181.827 186.466 180.787C186.314 183.209 185.506 185.175 183.118 186.013C180.736 186.85 178.759 186.142 177.078 184.128C176.052 186.371 174.534 188.022 171.972 188C169.506 187.98 167.88 186.573 167.042 184.274C162.531 188.464 157.635 185.712 157.438 180.94C151.827 183.174 148.319 179.329 149.655 174.527C144.646 174.964 141.299 170.737 144.566 165.794C141.98 165.146 140.207 163.692 140.014 160.939C139.854 158.677 141.101 157.145 143.089 156.002C140.876 154.715 139.668 152.958 140.116 150.38C140.558 147.838 142.368 146.745 144.835 146.22C143.215 144.298 142.634 142.269 143.875 140.088C145.103 137.927 147.126 137.227 149.752 137.605L149.75 137.61Z" fill="#5955FF"/>
            <path d="M219.095 168.066C222.02 165.113 224.952 162.153 228.31 158.759C226.629 158.759 225.5 158.759 224.373 158.759C221.726 158.759 219.081 158.741 216.435 158.776C215.481 158.787 215.104 158.513 215.06 157.455C214.882 153.268 214.835 153.263 218.927 153.239C221.34 153.225 223.753 153.253 226.164 153.244C226.69 153.244 227.251 153.403 227.91 152.865C224.969 149.9 222.041 146.951 219.088 143.975C219.918 142.712 221.083 141.897 221.99 140.846C222.656 140.073 223.168 140.118 223.872 140.846C225.923 142.979 228.05 145.04 230.12 147.157C230.66 147.71 231.095 148.361 231.509 148.879C232.53 148.364 232.215 147.738 232.219 147.263C232.252 143.989 232.266 140.712 232.219 137.438C232.205 136.441 232.519 136.12 233.538 136.071C237.828 135.865 237.824 135.829 237.821 140.101C237.821 142.954 237.821 145.806 237.821 149.223C241.142 145.93 244.081 143.014 247.076 140.045C248.581 141.232 249.79 142.523 251.193 144.169C248.154 147.082 245.137 149.972 241.724 153.244C245.247 153.244 248.247 153.228 251.245 153.249C255.825 153.279 254.885 152.717 254.979 157.12C255.011 158.71 254.946 158.745 253.26 158.757C249.631 158.78 246.003 158.764 241.778 158.764C245.074 162.101 247.988 165.055 250.863 167.966C250.807 168.202 250.819 168.387 250.735 168.471C249.585 169.614 248.635 171.582 247.197 171.635C245.736 171.689 244.787 169.678 243.625 168.565C241.787 166.804 240.047 164.944 237.814 163.279C237.814 164.56 237.814 165.841 237.814 167.122C237.814 169.696 237.798 172.268 237.824 174.841C237.831 175.605 237.599 176.008 236.767 175.982C235.677 175.947 234.585 175.923 233.501 175.994C232.423 176.064 232.208 175.542 232.219 174.6C232.259 171.872 232.233 169.144 232.233 166.413C232.233 165.411 232.233 164.408 232.233 163.382C231.354 163.275 231.085 163.961 230.683 164.354C228.457 166.535 226.28 168.764 224.064 170.954C222.921 172.083 222.872 172.09 221.712 170.909C220.847 170.029 219.851 169.265 219.095 168.066Z" fill="#C7F494"/>
            <path d="M259.75 214.61C259.103 212.163 259.235 210.166 261.043 208.599C263.031 206.877 265.205 207.045 267.511 208.212C267.607 205.734 268.483 203.771 270.881 202.951C273.148 202.177 275.14 202.836 276.708 204.569C277.267 204.573 277.297 204.123 277.457 203.826C278.442 201.994 279.965 200.956 282.07 201.001C284.161 201.045 285.693 202.069 286.583 203.971C286.71 204.243 286.858 204.503 287.039 204.852C288.726 202.841 290.781 202.111 293.189 203.005C295.491 203.857 296.405 205.717 296.359 208.23C298.57 207.132 300.712 206.822 302.681 208.395C304.647 209.967 305.01 212.033 304.173 214.494C306.555 214.419 308.724 214.742 310 216.927C311.271 219.104 310.866 221.142 309.211 223.156C311.65 223.776 313.516 224.977 313.922 227.485C314.322 229.948 313.135 231.717 310.851 232.971C313.112 234.219 314.333 236.024 313.921 238.496C313.516 240.938 311.767 242.236 309.225 242.763C310.723 244.777 311.312 246.802 310.062 248.966C308.848 251.068 306.817 251.617 304.359 251.54C304.85 253.877 304.71 256.025 302.741 257.558C300.823 259.051 298.726 258.827 296.466 257.787C296.314 260.209 295.506 262.175 293.118 263.013C290.736 263.85 288.759 263.142 287.078 261.128C286.052 263.371 284.534 265.022 281.972 265C279.506 264.98 277.88 263.573 277.042 261.274C272.531 265.464 267.635 262.712 267.438 257.94C261.827 260.174 258.319 256.329 259.655 251.527C254.646 251.964 251.299 247.737 254.566 242.794C251.98 242.146 250.207 240.692 250.014 237.939C249.854 235.677 251.101 234.145 253.089 233.002C250.876 231.715 249.668 229.958 250.116 227.38C250.558 224.838 252.368 223.745 254.835 223.22C253.215 221.298 252.634 219.269 253.875 217.088C255.103 214.927 257.126 214.227 259.752 214.605L259.75 214.61Z" fill="#B9E2F9"/>
            <path d="M331.75 214.61C331.103 212.163 331.235 210.166 333.043 208.599C335.031 206.877 337.205 207.045 339.511 208.212C339.607 205.734 340.483 203.771 342.881 202.951C345.148 202.177 347.14 202.836 348.708 204.569C349.267 204.573 349.297 204.123 349.457 203.826C350.442 201.994 351.965 200.956 354.07 201.001C356.161 201.045 357.693 202.069 358.583 203.971C358.71 204.243 358.858 204.503 359.039 204.852C360.726 202.841 362.781 202.111 365.189 203.005C367.491 203.857 368.405 205.717 368.359 208.23C370.57 207.132 372.712 206.822 374.681 208.395C376.647 209.967 377.01 212.033 376.173 214.494C378.555 214.419 380.724 214.742 382 216.927C383.271 219.104 382.866 221.142 381.211 223.156C383.65 223.776 385.516 224.977 385.922 227.485C386.322 229.948 385.135 231.717 382.851 232.971C385.112 234.219 386.333 236.024 385.921 238.496C385.516 240.938 383.767 242.236 381.225 242.763C382.723 244.777 383.312 246.802 382.062 248.966C380.848 251.068 378.817 251.617 376.359 251.54C376.85 253.877 376.71 256.025 374.741 257.558C372.823 259.051 370.726 258.827 368.466 257.787C368.314 260.209 367.506 262.175 365.118 263.013C362.736 263.85 360.759 263.142 359.078 261.128C358.052 263.371 356.534 265.022 353.972 265C351.506 264.98 349.88 263.573 349.042 261.274C344.531 265.464 339.635 262.712 339.438 257.94C333.827 260.174 330.319 256.329 331.655 251.527C326.646 251.964 323.299 247.737 326.566 242.794C323.98 242.146 322.207 240.692 322.014 237.939C321.854 235.677 323.101 234.145 325.089 233.002C322.876 231.715 321.668 229.958 322.116 227.38C322.558 224.838 324.368 223.745 326.835 223.22C325.215 221.298 324.634 219.269 325.875 217.088C327.103 214.927 329.126 214.227 331.752 214.605L331.75 214.61Z" fill="#FF613A"/>
            <path d="M-29.6845 205.419C-4.07773 207.708 16.3198 199.596 33.0553 179.347C30.9054 204.999 39.2607 225.336 59.0311 242.101C32.9837 239.902 12.7986 248.401 -3.49942 268.116C-1.42145 242.428 -9.62968 221.991 -29.6845 205.419Z" fill="#FFE2DA"/>
            <path d="M65.3155 80.4188C90.9223 82.7084 111.32 74.5956 128.055 54.3475C125.905 79.9991 134.261 100.336 154.031 117.101C127.984 114.902 107.799 123.401 91.5006 143.116C93.5786 117.428 85.3703 96.9905 65.3155 80.4188Z" fill="#B9E2F9"/>
            <path d="M-8.33292 54.7322C-19.049 52.3923 -25.6619 46.15 -26.6209 35.0014C-27.3021 27.0953 -22.2449 18.6915 -15.0496 15.6832C-4.89127 11.4322 3.78224 13.9008 11.0691 22.7257C13.609 11.4457 20.1056 4.76194 31.4259 4.36207C39.9766 4.05807 46.3998 8.40604 50.1392 16.1157C54.2843 24.664 52.1928 32.3618 43.4195 42.1225C54.3999 44.7309 61.0556 51.282 61.4439 62.7948C61.7281 71.1818 56.54 78.8822 48.6473 81.7632C38.8397 85.3463 30.6337 82.3698 23.8222 74.0418C21.3792 85.0509 15.2062 91.8917 3.92079 92.5104C-4.73238 92.985 -12.2078 87.9926 -15.5202 80.3707C-19.3039 71.665 -17.0026 63.2956 -8.33292 54.7322Z" fill="#1F3934"/>
          </g>
          <defs>
            <clipPath id="clip0_268_3055">
              <rect width="375" height="265" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </header>
  )
}